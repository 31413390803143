module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-164782114-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lunaar - Augmentez l'efficacité de votre Veille.","short_name":"Lunaar","start_url":"/","background_color":"#5E6EAF","theme_color":"#5E6EAF","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"16324e06799abbceddea42a2919eec4c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","fr"],"defaultLanguage":"fr","path":"/usr/src/app/www/locales","siteUrl":"https://www.lunaar.app","i18nextOptions":{"debug":false,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/terms","languages":["en"]},{"matchPath":"/privacy","languages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
